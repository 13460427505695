import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import { Container } from "reactstrap";
import NavMenu from "./NavMenu";

interface Prop {
  children: any;
}

const Layout = (props: Prop) => {
  return (
    <div>
      <NavMenu {...props.children} />
      <AuthenticatedTemplate>
        <Container>{props.children}</Container>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Container>
          <p>Please sign in</p>
        </Container>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default Layout;
