import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {
  InsightsParam,
  SummaryReportParam,
} from "../interfaces/InputInterfaces";
import { msalInstance } from "./msal/MsalInstance";

let secureApi = "";

// helper function to retrieve token for secure API calls
async function getAuthHeader() {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    // no account signed in
    console.log("No account signed in");
  } else {
    const accessTokenRequest = {
      account: accounts[0],
      scopes: ["https://metroquestlabs.com/MQManage/user_impersonation"],
    };

    let accessToken = null;
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      accessToken = tokenResponse.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    }
    return {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    };
  }
}

export async function getFilteredHealthDashboardData(customerType: string, csm: string, accountStatus: string) {
  try {
    const url =
      secureApi + "health/dashboard?accountStatus=" +
      accountStatus + "&" + "customerType=" + customerType + "&csm=" + csm;
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };
    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response)
      throw new Error("Health dashboard failed");
    }
    const data = await response.json();
    return data;

  } catch (error) {
    console.log(error);
  }
}
export async function getHealthDashboardAllData(pSecureApi: string, customer: string, csm: string, accountStatus: string) {

  try {
    secureApi = pSecureApi;
    const url =
      secureApi + "health/dashboard?accountStatus=" +
      accountStatus + "&" + "customerType='" + customer + "'&csm=" + csm + "&userId=6";
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };
    const response = await fetch(url, body);
    if (!response.ok) {
      throw new Error("Health dashboard failed");
    }
    const data = await response.json();
    return data;

  } catch (error) {
    console.log(error);
  }
}

export async function getCustomers(pSecureApi: string) {
  try {
    secureApi = pSecureApi;
    const url = secureApi + "customers?u=false";
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response);
      throw new Error("getCustomers failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getUsers(customerId: string) {
  try {
    const url = secureApi + "users?customerid=" + customerId;
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response);
      throw new Error("getUsers failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getProjects(customerId: string) {
  try {
    const url = secureApi + "customer/" + customerId + "/projects";
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response);
      throw new Error("getProjects failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getScreens(projectId: string) {
  try {
    const url =
      secureApi + "screens/GetScreensForProject?projectid=" + projectId;
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response);
      throw new Error("getScreens failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getCategories(product: string) {
  try {
    const url =
      secureApi +
      "trackingmonitoring/GetCategoriesForProduct?product=" +
      product;
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response);
      throw new Error("getCategories failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getActions(category: string) {
  try {
    const url =
      secureApi +
      "trackingmonitoring/getactionsforcategory?category=" +
      category.replace(" ", "%20");
    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "GET",
      headers: header ? header : undefined,
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      console.log(response);
      throw new Error("getActions failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadExcelReport(params: InsightsParam) {
  try {
    let url = secureApi;
    url += "TrackingMonitoring/Export";

    const formFilters: InsightsParam = {
      customerId: params.customerId,
      projectId: params.projectId,
      product: params.product,
      userId: params.userId,
      screenId: params.screenId,
      startDate: params.startDate,
      endDate: params.endDate,
    };

    if (params.screenType) {
      formFilters.screenType = params.screenType;
    }

    if (params.category) {
      formFilters.category = params.category;
    }

    if (params.action) {
      formFilters.action = params.action;
    }

    console.log(formFilters);

    const header = await getAuthHeader();
    const requestOptions: RequestInit = {
      method: "PUT",
      headers: header ? header : undefined,
      body: JSON.stringify(formFilters),
    };

    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      console.log(response);
      throw new Error("downloadExcelReport failed");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadSummaryReport(params: SummaryReportParam) {
  try {
    let url = secureApi;
    url += "kpi/download/internal?userId=0";

    const formFilters: SummaryReportParam = {
      customerId: params.customerId,
      startDate: params.startDate,
      endDate: params.endDate,
    };

    const header = await getAuthHeader();
    const body: RequestInit = {
      method: "PUT",
      headers: header ? header : undefined,
      body: JSON.stringify(formFilters),
    };

    const response = await fetch(url, body);
    if (!response.ok) {
      throw new Error("downloadSummaryReport failed");
    }

    const data = await response.json();
    if (data === "no data") {
      return null;
    }
    return process.env.REACT_APP_BLOB_ROOT + data;
  } catch (error) {
    console.log(error);
  }
}
