
import React, { useEffect, useState } from 'react';

import styles from "./Home.module.scss"
import { Container, Row } from "reactstrap";

import Filter from "../filters/Filter";
import Tables from "../tables/Tables";

import { getFilteredHealthDashboardData, getHealthDashboardAllData } from "../../services/ApiService"
import Charts from "../charts/Charts";


function Home() {
  const [totalAccounts, setTotalAccounts] = useState([]);
  const [listData, setListData] = useState([]);
  const [mounted, setMounted] = useState(true); // to be false after first API call

  // Call API for Filtered Data
  async function getInitialData(apiLoc: string) {
    const customer = 'All';
    const csm = 'All';
    const accountStatus = 'All';
    try {
      if (mounted) {
        setTotalAccounts(await getHealthDashboardAllData(apiLoc, customer, csm, accountStatus))
      }

    } catch (err) {
      console.log("Could not fetch the initial data")
    }
  }

  async function getFilteredData(customerType: string[], csm: string, accountStatus: string) {
    const customerTypeString = "'" + customerType.join("','").split(',').join("%2C") + "'";
    setListData(await getFilteredHealthDashboardData(customerTypeString, csm, accountStatus))
  }

  useEffect(() => {
    async function getCustomers() {
      await getInitialData(
        process.env.REACT_APP_SECURE_URL_LOC
          ? process.env.REACT_APP_SECURE_URL_LOC
          : ""
      );
    }
    getCustomers();
    return () => {
      setMounted(false)
    }
  }, []);

  return (
    <div className={styles.body}>
      <Row className={styles.header}>
        <h4>Welcome to MQ Central Health page</h4>
      </Row>
      <Container className={styles.charts} data-testid='charts-container'>
        <Charts totalAccounts={totalAccounts} />
      </Container>
      <Container className={styles.filters} data-testid='filter-container' >
        {/* Filters */}
        <Filter getFilteredData={getFilteredData} />
        {/* List of companies */}
        <div className={styles.table_header}>

          <Tables listData={listData} />
        </div>
      </Container>


    </div >
  );
};

export default Home;
