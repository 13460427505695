import React, { useState } from "react"
import Select from 'react-select'
import { Button, Col, Container, Row } from "reactstrap"

const Filter = ({ getFilteredData }: { getFilteredData: any }) => {
    const [customerType, setCustomerType] = useState<string[]>(['All'])
    const [csm, setCsm] = useState<string>('All')
    const [accountStatus, setAccountStatus] = useState<string>('All')

    const handleCustomerTypeChange = (
        selectedOptions: any) => {
        setCustomerType(selectedOptions ? (selectedOptions as { value: string }[]).map(c => c.value) : [])
    }
    const handleCmsChange = (
        selectedOptions: any) => {
        setCsm(selectedOptions.value)
    }
    const handleAccountStatusChange = (selectedOptions: any) => {
        setAccountStatus(selectedOptions.value)
    }


    const customerTypeOptions = [
        { key: 0, value: 'All', label: 'All' },
        { key: 1, value: 'DOT', label: 'Department of Transportation (DOT)' },
        { key: 2, value: 'MPO', label: 'Metropolitan Planning (MPO)' },
        { key: 3, value: 'RPO/RPA/COG/AG', label: 'Regional Planning (RPO/RPA/COG/AG)' },
        { key: 4, value: 'RTA', label: 'Regional Transportation (RTA)' },
        { key: 5, value: 'Local Government', label: 'Local Government (City, County, Town)' },
        { key: 6, value: 'Transit', label: 'Transit' },
        { key: 7, value: 'State / Federal Government', label: 'State / Federal Government' },
        { key: 8, value: 'Firm', label: 'Consulting Firm' },
        { key: 9, value: 'Non Profit / Association', label: 'Non Profit / Association' },
        { key: 10, value: 'Other', label: 'Other' },
    ];

    const csmOptions = [
        { key: 0, value: 'All', label: 'All' },
        { key: 1, value: 'Emily Margitan', label: 'Emily Margitan' },
        { key: 2, value: 'Gerard Penalosa', label: 'Gerard Penalosa' },
    ]

    const accountStatusOptions = [
        { key: 0, value: 'All', label: 'All' },
        { key: 1, value: 'Active', label: 'Active' },
        { key: 2, value: 'Deactivated', label: 'Deactivated' },
    ]

    return (
        <Container className="border rounded d-flex flex-column justify-content-around mt-5 mb-3 p-4 bg-secondary">
            <h3>Filters:</h3>
            <Row className="pb-3">

                <Col className="pb-3">
                    <h6>Customer Success Manager:</h6>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={csmOptions[0]}
                        options={csmOptions}
                        onChange={handleCmsChange}
                    />
                </Col>
                <Col>
                    <h6>Account Status:</h6>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={accountStatusOptions[0]}
                        options={accountStatusOptions}
                        onChange={handleAccountStatusChange}
                    />
                </Col>
            </Row>
            <Row className="pb-3">
                <h6>Customer Type:</h6>
                <Select
                    isMulti
                    defaultValue={customerTypeOptions[0]}
                    options={customerType.includes('All') === true ? [customerTypeOptions[0]] : customerTypeOptions}
                    onChange={handleCustomerTypeChange}
                />
            </Row>
            <Button color="primary" size="lg" className="w-25 d-flex align-self-center justify-content-center
             "onClick={() => getFilteredData(customerType, csm, accountStatus)}>Submit</Button>
        </Container >
    )
}

export default Filter;