import React from "react";
import { Route } from "react-router";
import { MsalProvider } from "@azure/msal-react";

import Layout from "./components/Layout";
import Home from "./components/pages/Home";
import Insights from "./components/pages/Insights";
import { AuthContextProvider } from "./store/auth-context";

import "./global/overrides.scss";
import Reports from "./components/pages/Reports";

export default function App({ msalInstance }: any) {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthContextProvider>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route exact path="/insights" component={Insights} />
          <Route exact path="/reports" component={Reports} />
        </Layout>
      </AuthContextProvider>
    </MsalProvider>
  );
}
