import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CustomInput from "../forms/CustomInput";
import { downloadSummaryReport } from "../../services/ApiService";
import styles from "./Reports.module.scss";
import {
  clearInputFields,
  formatDate,
  getCustomerList,
  resetAllToValid,
  setIsInputValid,
} from "../forms/InputHelpers";
import { SummaryReportParam } from "../../interfaces/InputInterfaces";

const initialReportState = {
  customersList: [{ key: 0, value: "", label: "--Select Customer--" }],
  customerId: null,
  startDate: null,
  endDate: null,
};

const reportStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        customersList: action.customers,
      };
    case "SET_CUSTOMER":
      return {
        ...state,
        customerId: action.customerId,
      };

    case "SET_START_DATE":
      return { ...state, startDate: action.startDate };

    case "SET_END_DATE":
      return { ...state, endDate: action.endDate };

    case "RESET":
      return initialReportState;

    default:
      throw new Error(`UNKNOWN ACTION TYPE: ${action.type}`);
  }
};

const Reports = () => {
  const [reportState, dispatch] = useReducer(
    reportStateReducer,
    initialReportState
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasNoData, setHasNoData] = useState(false);
  const ids = ["customer", "start_date", "end_date"];
  const defaultValues = ["", null, null];

  const toggleModal = () => {
    setHasNoData(!hasNoData);
  };

  const customerChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const customerId = e.target.value ? e.target.value : null;
    dispatch({ type: "SET_CUSTOMER", customerId });
    setIsInputValid("customer", true);
  };

  const startDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value).toUTCString();
    const startDate = date !== "Invalid Date" ? date : null;
    dispatch({ type: "SET_START_DATE", startDate });
    setIsInputValid("start_date", true);
  };

  const endDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value).toUTCString();
    const endDate = date !== "Invalid Date" ? date : null;
    dispatch({ type: "SET_END_DATE", endDate });
    setIsInputValid("end_date", true);
  };

  const resetButtonHandler = () => {
    const customers = reportState.customersList;
    dispatch({ type: "RESET" });
    dispatch({ type: "INIT", customers });
    clearInputFields(ids, defaultValues);
  };

  const validateInput = () => {
    let valid = true;
    resetAllToValid(ids); // Clear Invalid UI

    // Cutomer Not Empty
    if (reportState.customerId == null) {
      setIsInputValid("customer", false);
      valid = false;
    }

    // Valid Date Range
    if (
      reportState.startDate != null &&
      reportState.endDate != null &&
      Date.parse(reportState.startDate) > Date.parse(reportState.endDate)
    ) {
      setIsInputValid("start_date", false);
      setIsInputValid("end_date", false);
      valid = false;
    }

    return valid;
  };

  const downloadButtonHandler = async () => {
    if (validateInput()) {
      setIsDownloading(true);

      const params: SummaryReportParam = {
        customerId: reportState.customerId,
        startDate: reportState.startDate
          ? formatDate(reportState.startDate)
          : null,
        endDate: reportState.endDate ? formatDate(reportState.endDate) : null,
      };

      const excelUrl = await downloadSummaryReport(params);
      if (excelUrl != null) window.open(excelUrl, "_self");
      else setHasNoData(true);
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    async function getCustomers() {
      const customers = await getCustomerList(
        process.env.REACT_APP_SECURE_URL_LOC
          ? process.env.REACT_APP_SECURE_URL_LOC
          : ""
      );
      dispatch({ type: "INIT", customers });
    }
    getCustomers();
  }, []);

  return (
    <>
      <Modal isOpen={hasNoData} toggle={toggleModal}>
        <ModalHeader>No Data Found</ModalHeader>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <h5>Usage Summary</h5>
      <Form className={styles.form}>
        <h6>Summary Report Filters</h6>
        <Row>
          <Col md="5" className={styles.form__label}>
            <CustomInput
              type="select"
              options={reportState.customersList}
              label="Customer"
              id="customer"
              onChange={customerChangeHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <CustomInput
              type="date"
              label="Start Date"
              id="start_date"
              onChange={startDateChangeHandler}
            />
          </Col>
          <Col md="5">
            <CustomInput
              type="date"
              label="End Date"
              id="end_date"
              onChange={endDateChangeHandler}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.form__buttons}>
              <Button color="secondary" onClick={resetButtonHandler}>
                <FontAwesomeIcon icon={regular("arrows-rotate")} />
                Reset
              </Button>
              <Button
                color="primary"
                className="margin-left-auto"
                onClick={downloadButtonHandler}
                disabled={isDownloading}
              >
                {isDownloading ? (
                  <>
                    <FontAwesomeIcon icon={regular("spinner")} spin />
                    Downloading
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={regular("download")} />
                    Download Summary
                  </>
                )}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Reports;
