import React, { useReducer, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import CustomInput from "../forms/CustomInput";
import { downloadExcelReport } from "../../services/ApiService";
import "../../global/utilities.scss";
import styles from "./Insights.module.scss";
import {
  clearInputFields,
  getActionsList,
  getCategoryList,
  getCustomerList,
  getProjectList,
  getScreenList,
  getUserList,
  resetAllToValid,
  resetInput,
  setIsInputValid,
} from "../forms/InputHelpers";

const initialInsightsState = {
  productSelected: false,
  productList: [
    { key: 0, value: "public", label: "2020 Editor" },
    { key: 1, value: "studio", label: "Studio" },
  ],
  customersList: [],
  screenTypeList: [
    { key: 0, value: "", label: "--Select Screen Type--" },
    { key: 1, value: "CHP", label: "Budget Allocation" },
    { key: 2, value: "PRJ", label: "Project Selection" },
    { key: 3, value: "INT", label: "Welcome" },
    { key: 4, value: "IMV", label: "Visual Preference" },
    { key: 5, value: "STIP", label: "STIP" },
    { key: 6, value: "BDG", label: "Funding Balance" },
    { key: 7, value: "TRD", label: "Tradeoffs" },
    { key: 8, value: "IMC", label: "Image Rating" },
    { key: 9, value: "MRK", label: "Map Marker" },
    { key: 10, value: "SRV", label: "Standard Survey" },
    { key: 11, value: "RNK", label: "Priority Ranking" },
    { key: 12, value: "XIT", label: "Wrap Up" },
    { key: 13, value: "VIS", label: "Vision Statement" },
    { key: 14, value: "SCN", label: "Scenario Rating" },
    { key: 15, value: "STR", label: "Startegy Rating" },
  ],
  usersList: [],
  projectsList: [],
  screensList: [],
  categoriesList: [],
  actionsList: [],
  product: null,
  customerId: null,
  userId: null,
  projectId: null,
  screenId: null,
  startDate: null,
  endDate: null,
  category: null,
  action: null,
  screenType: null,
};

const insightsStateReducer = (state: any, action: any) => {
  switch (action.type) {
    case "INIT":
      return {
        ...state,
        productSelected: true,
        product: action.product,
        customersList: action.customers,
        categoriesList: action.categories,
      };
    case "SET_PRODUCT":
      return { ...state, product: action.product };
    case "SET_CUSTOMER":
      return {
        ...state,
        customerId: action.customerId,
        userId: null,
        projectId: null,
        screenId: null,
        usersList: action.users,
        projectsList: action.projects,
        screensList: [],
      };
    case "SET_USER":
      return { ...state, userId: action.userId };
    case "SET_PROJECT":
      return {
        ...state,
        projectId: action.projectId,
        screensList: action.screens,
        screenId: null,
      };
    case "SET_SCREEN":
      return { ...state, screenId: action.screenId };
    case "SET_SCREEN_TYPE":
      return { ...state, screenType: action.screenType };
    case "SET_START_DATE":
      return { ...state, startDate: action.startDate };
    case "SET_END_DATE":
      return { ...state, endDate: action.endDate };
    case "SET_CATEGORY_LIST":
      return {
        ...state,
        categoriesList: action.categoriesList,
      };
    case "SET_CATEGORY":
      return {
        ...state,
        category: action.category,
        actionsList: action.actions,
        action: null,
      };
    case "SET_ACTION":
      return { ...state, action: action.action };
    case "RESET":
      return initialInsightsState;
    case "RESET_CUSTOMER":
      return {
        ...state,
        customerId: null,
        userId: null,
        usersList: [],
        projectId: null,
        projectsList: [],
        screenId: null,
        screensList: [],
      };
    case "RESET_PROJECT":
      return {
        ...state,
        screenId: null,
        screensList: [],
        projectId: null,
      };
    case "RESET_START_DATE":
      return {
        ...state,
        startDate: null,
      };
    case "RESET_END_DATE":
      return {
        ...state,
        endDate: null,
      };
    case "RESET_CATEGORY":
      return {
        ...state,
        category: null,
        action: null,
        actionsList: [],
      };

    default:
      throw new Error(`UNKNOWN ACTION TYPE: ${action.type}`);
  }
};

const Insights = () => {
  const [insightsState, dispatch] = useReducer(
    insightsStateReducer,
    initialInsightsState
  );
  const ids = [
    "customer",
    "user",
    "project",
    "screen",
    "ScreenType",
    "start_date",
    "end_date",
    "category",
    "action",
  ];
  const defaultValues = ["", "", "", "", "", null, null, "", ""];
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasNoData, setHasNoData] = useState(false);

  const toggleModal = () => {
    setHasNoData(!hasNoData);
  };

  const productButtonHandler = async (product: string, apiLoc: string) => {
    const customers = await getCustomerList(apiLoc);
    const categories = await getCategoryList(product);
    dispatch({ type: "INIT", product, customers, categories });
    resetInput("product", product);
  };

  const productSelectHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const product = e.target.value ? e.target.value : null;
    const categoriesList = await getCategoryList(product);
    resetInput("category", "");

    dispatch({ type: "SET_PRODUCT", product });
    dispatch({ type: "SET_CATEGORY_LIST", categoriesList });
    dispatch({ type: "RESET_CATEGORY" });
  };

  const customerSelectHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const customerId = e.target.value ? e.target.value : null;
    setIsInputValid("customer", true);

    if (customerId != null) {
      const users = await getUserList(customerId);
      const projects = await getProjectList(customerId);

      if (users && projects)
        dispatch({
          type: "SET_CUSTOMER",
          customerId,
          users,
          projects,
        });
    } else {
      dispatch({ type: "RESET_CUSTOMER" });
    }
  };

  const userSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userId = e.target.value ? e.target.value : null;
    dispatch({ type: "SET_USER", userId });
  };

  const screenTypeSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const screenType = e.target.value ? e.target.value : null;
    dispatch({ type: "SET_SCREEN_TYPE", screenType });
  };

  const projectSelectHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const projectId = e.target.value ? e.target.value : null;
    if (projectId != null) {
      const screens = await getScreenList(projectId);
      if (screens) dispatch({ type: "SET_PROJECT", projectId, screens });
    } else {
      dispatch({ type: "RESET_PROJECT" });
    }
  };

  const screenSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const screenId = e.target.value ? e.target.value : null;
    dispatch({ type: "SET_SCREEN", screenId });
  };

  const startDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value).toUTCString();
    const startDate = date !== "Invalid Date" ? date : null;
    dispatch({ type: "SET_START_DATE", startDate });
    setIsInputValid("start_date", true);
  };

  const endDateChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.value).toUTCString();
    const endDate = date !== "Invalid Date" ? date : null;
    dispatch({ type: "SET_END_DATE", endDate });
    setIsInputValid("end_date", true);
  };

  const categorySelectHandler = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const category = e.target.value ? e.target.value : null;
    if (category != null) {
      const actions = await getActionsList(category);
      if (actions) dispatch({ type: "SET_CATEGORY", category, actions });
    } else {
      dispatch({ type: "RESET_CATEGORY" });
    }
  };

  const actionSelectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const action = e.target.value ? e.target.value : null;
    dispatch({ type: "SET_ACTION", action });
  };

  const resetButtonHandler = () => {
    const product = insightsState.product;
    const customers = insightsState.customersList;
    const categories = insightsState.categoriesList;

    dispatch({ type: "RESET" });
    dispatch({ type: "INIT", product, customers, categories });
    clearInputFields(ids, defaultValues);
  };

  const validateInput = () => {
    let isValid = true;
    resetAllToValid(ids);

    if (
      insightsState.startDate != null &&
      insightsState.endDate != null &&
      Date.parse(insightsState.startDate) > Date.parse(insightsState.endDate)
    ) {
      setIsInputValid("start_date", false);
      setIsInputValid("end_date", false);
      isValid = false;
    }
    return isValid;
  };

  const downloadButtonHandler = async () => {
    if (validateInput()) {
      setIsDownloading(true);
      const params = {
        customerId: insightsState.customerId,
        projectId: insightsState.projectId,
        userId: insightsState.userId,
        screenId: insightsState.screenId,
        product: insightsState.product,
        category: insightsState.category,
        action: insightsState.action,
        startDate: insightsState.startDate,
        endDate: insightsState.endDate,
        screenType: insightsState.screenType,
      };
      const excelUrl = await downloadExcelReport(params);
      if (excelUrl != null) window.open(excelUrl, "_self");
      else setHasNoData(true);
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Modal isOpen={hasNoData} toggle={toggleModal}>
        <ModalHeader>No Data Found</ModalHeader>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {!insightsState.productSelected && (
        <Row>
          <h3 className={styles.heading}>Select Product</h3>
          <Col className="d-flex">
            <Button
              color="primary"
              size="lg"
              className={`margin-left-auto ${styles["product-button"]}`}
              onClick={() =>
                productButtonHandler(
                  "public",
                  process.env.REACT_APP_SECURE_URL_LOC
                    ? process.env.REACT_APP_SECURE_URL_LOC
                    : ""
                )
              }
            >
              <FontAwesomeIcon icon={regular("square-poll-vertical")} beat />{" "}
              2020 Editor
            </Button>
          </Col>
          <Col>
            <Button
              color="primary"
              size="lg"
              className={styles["product-button"]}
              onClick={() =>
                productButtonHandler(
                  "studio",
                  process.env.REACT_APP_SECURE_URL_LOC
                    ? process.env.REACT_APP_SECURE_URL_LOC
                    : ""
                )
              }
            >
              <FontAwesomeIcon icon={solid("bars-progress")} beat /> Studio
            </Button>
          </Col>
        </Row>
      )}
      {insightsState.productSelected && (
        <Form className={styles.form}>
          <h5>Product Usage Events</h5>
          <Row>
            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.productList}
                label="Select Product"
                id="product"
                onChange={productSelectHandler}
              />
            </Col>
          </Row>
          <h6>Studio Object Filters</h6>
          <Row>
            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.customersList}
                label="Customer"
                id="customer"
                onChange={customerSelectHandler}
              />
            </Col>

            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.usersList}
                label="User"
                id="user"
                onChange={userSelectHandler}
                className={
                  insightsState.customerId && insightsState.usersList.length > 0
                    ? "d-block"
                    : "d-none"
                }
              />
            </Col>

            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.projectsList}
                label="Project"
                id="project"
                onChange={projectSelectHandler}
                className={
                  insightsState.customerId &&
                  insightsState.projectsList.length > 0
                    ? "d-block"
                    : "d-none"
                }
              />
            </Col>
            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.screensList}
                label="Screen"
                id="screen"
                onChange={screenSelectHandler}
                className={
                  insightsState.projectId &&
                  insightsState.screensList.length > 0
                    ? "d-block"
                    : "d-none"
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.screenTypeList}
                label="Screen Type"
                id="ScreenType"
                onChange={screenTypeSelectHandler}
              />
            </Col>

            <Col md="3">
              <CustomInput
                type="date"
                label="Start Date"
                id="start_date"
                onChange={startDateChangeHandler}
              />
            </Col>
            <Col md="3">
              <CustomInput
                type="date"
                label="End Date"
                id="end_date"
                onChange={endDateChangeHandler}
              />
            </Col>
          </Row>
          <h6>Event Category Filters</h6>
          <Row>
            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.categoriesList}
                label="Category"
                id="category"
                onChange={categorySelectHandler}
              />
            </Col>
            <Col md="3">
              <CustomInput
                type="select"
                options={insightsState.actionsList}
                label="Action"
                id="action"
                onChange={actionSelectHandler}
                className={
                  insightsState.category && insightsState.actionsList.length > 0
                    ? "d-block"
                    : "d-none"
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.form__buttons}>
                <Button color="secondary" onClick={resetButtonHandler}>
                  <FontAwesomeIcon icon={regular("arrows-rotate")} />
                  Reset
                </Button>
                <Button
                  color="primary"
                  className="margin-left-auto"
                  onClick={downloadButtonHandler}
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <>
                      <FontAwesomeIcon icon={regular("spinner")} spin />
                      Downloading
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={regular("download")} />
                      Download Summary
                    </>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default Insights;
