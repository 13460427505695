export const msalConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/2e865213-99de-4e41-932d-38a739f0da7e",
    clientId: "9665c6f5-c075-4672-a00d-fe78a9692578", // MQ900HQ2
    redirectUri: document.getElementById("root")?.baseURI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored,
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
