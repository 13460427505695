import React, { useState } from "react";
import { Table, Tooltip } from "reactstrap";

import styles from "./Table.module.scss"
import Trends from "../Trends"


interface ListProps {
    listData: any[];
}

const Tables: React.FC<ListProps> = ({ listData }) => {
    const [openTooltipId, setOpenTooltipId] = useState("");

    const toggle = (customerId: string) => {
        if (openTooltipId === customerId) {
            setOpenTooltipId("");
        } else {
            setOpenTooltipId(customerId);
        }
    };
    return (
        <div>
            <Table striped>
                <thead>
                    <tr>
                        <th>Row</th>
                        <th>Customer Name</th>
                        <th>Monthly Activity</th>
                        <th>Monthly Participant</th>
                        <th>Monthly Survey Launch</th>
                        <th>Customer Type</th>
                        <th>CSM</th>
                        <th>Location</th>
                        <th>Year Joined</th>
                        <th>Account Status</th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((content, i) => (
                        <tr key={i} id={content.customerId}>
                            <td className={styles.row}>
                                {i}
                            </td>
                            <th scope="row" id={content.customerId}>
                                {content.customerName}
                            </th>
                            <td className={styles.trend} id={`btn-activity-${content.customerId}`}>
                                <Trends props={[content.averageMonthlyActivity, content.last30DaysActivity]} />
                                <Tooltip
                                    placement="left"
                                    isOpen={openTooltipId === content.customerId}
                                    target={`btn-activity-${content.customerId}`}
                                    toggle={() => toggle(content.customerId)}
                                >
                                    <p>Average Activity: {content.averageMonthlyActivity} </p>
                                    <p>Last 30 Days Activity: {content.last30DaysActivity} </p>
                                </Tooltip>

                            </td>
                            <td id={`btn-participant-${content.customerId}`}>
                                <Trends props={[content.averageMonthlyParticipants, content.last30DayParticipants]} />
                                <Tooltip
                                    placement="bottom"
                                    isOpen={openTooltipId === content.customerId}
                                    target={`btn-participant-${content.customerId}`}
                                    toggle={() => toggle(content.customerId)}
                                >
                                    <p>Average Participant Activity: {content.averageMonthlyParticipants} </p>
                                    <p>Last 30 Days Participant Activity: {content.last30DayParticipants} </p>
                                </Tooltip>
                            </td>
                            <td id={`btn-survey-${content.customerId}`}>
                                <Trends props={[content.averageMonthlySurveyLaunched, content.last30DaySurveyLaunched]} />
                                <Tooltip
                                    placement="right"
                                    isOpen={openTooltipId === content.customerId}
                                    target={`btn-survey-${content.customerId}`}
                                    toggle={() => toggle(content.customerId)}
                                >
                                    <p>Average Survey Launch Activity: {content.averageMonthlySurveyLaunched} </p>
                                    <p>Last 30 Days Survey Launch Activity: {content.last30DaySurveyLaunched} </p>
                                </Tooltip>
                            </td>
                            <td>
                                {content.orgType}
                            </td>
                            <td>
                                {content.CSM === "180542383" ? "Emily Margitan" : "Gerard Penalosa"}
                            </td>
                            <td>
                                {content.location}
                            </td>
                            <td>
                                {content.yearAdded}
                            </td>
                            <td>
                                {content.accountStatus}
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </Table>

        </div>
    )
}

export default Tables; 