import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Container, Row } from 'reactstrap';
import styles from "./Charts.module.scss"
import React from 'react';



ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

interface ChartsProps {
    totalAccounts: any[];
}

const Charts: React.FC<ChartsProps> = ({ totalAccounts }) => {
    let activeAccounts = 0
    let activePercents = 0
    let inactiveAccounts = 0
    let inactivePercents = 0
    let allAccounts = 0

    if (totalAccounts.length > 0) {
        activeAccounts = totalAccounts.filter(accountStatus =>
            Object.entries({ accountStatus: 'Customer' }).every(([key, value]) => accountStatus[key] === value)).length;
        inactiveAccounts = totalAccounts.filter(accountStatus =>
            Object.entries({ accountStatus: 'Past Customer' }).every(([key, value]) => accountStatus[key] === value)).length;
    }
    allAccounts = activeAccounts + inactiveAccounts;
    activePercents = (activeAccounts / allAccounts) * 100;
    inactivePercents = (inactiveAccounts / allAccounts) * 100;
    const options = {
        maintainAspectRatio: false
    };
    const data = {
        labels: ['% Past customers', '% Active customers'],
        datasets: [{
            data: [inactivePercents, activePercents],
            backgroundColor: ['#FF6384', '#36A2EB'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB']
        }]
    };

    return (
        <Container>
            <Row>
                <Doughnut
                    className={styles.doughnut}
                    data={data}
                    options={options}
                />
            </Row>
        </Container>
    )
}

export default Charts;