import {
  Customer,
  Option,
  Project,
  Screen,
  User,
} from "../../interfaces/InputInterfaces";
import {
  getActions,
  getCategories,
  getCustomers,
  getProjects,
  getScreens,
  getUsers,
} from "../../services/ApiService";

export const formatDate = (date: string) => {
  return new Date(date)
    .toISOString()
    .replace(/T/, " ") // replace T with a space
    .replace(/\..+/, "");
};

export const resetInput = (id: string, value: any) => {
  (document.getElementById(id) as HTMLInputElement).value = value;
  setIsInputValid(id, true);
};

export const setIsInputValid = (id: string, isValid: boolean) => {
  const label = document.getElementById(`form-label__${id}`);
  const input = document.getElementById(id);

  if (isValid) {
    input?.classList.remove("is-invalid");
    label?.classList.remove("text-danger");
  } else {
    input?.classList.add("is-invalid");
    label?.classList.add("text-danger");
  }
};

export const resetAllToValid = (ids: string[]) => {
  for (let i = 0; i < ids.length; i++) {
    setIsInputValid(ids[i], true);
  }
};

export const clearInputFields = (ids: string[], deafaults: any[]) => {
  for (let i = 0; i < ids.length; i++) {
    resetInput(ids[i], deafaults[i]);
  }
};

export const getCustomerList = async (apiLoc: string) => {
  const customersData: Customer[] = await getCustomers(apiLoc);
  const customers: Option[] = customersData?.map((customer: Customer) => {
    return {
      key: "customer_" + customer.id,
      value: customer.id.toString(),
      label: customer.name,
    };
  });

  customers?.unshift({ key: "0", value: "", label: "--Select Customer--" });
  return customers;
};

export const getUserList = async (customerId: string) => {
  if (customerId != null) {
    const usersData = await getUsers(customerId);
    const users = usersData.map((user: User) => {
      return {
        key: "user_" + usersData.indexOf(user),
        value: user.id,
        label: user.userName + " - " + user.firstName + " " + user.lastName,
      };
    });
    users.unshift({ key: 0, value: "", label: "--Select User--" });
    return users;
  }
  return null;
};

export const getProjectList = async (customerId: string) => {
  if (customerId != null) {
    const projectsData = await getProjects(customerId);
    const projects = projectsData
      .filter((project: Project) => {
        return project.stage !== "New" && project.stage !== "Archived";
      })
      .map((project: Project) => {
        return {
          key: "project_" + project.id,
          value: project.id,
          label: project.name,
        };
      });
    projects.unshift({ key: 0, value: "", label: "--Select Project--" });
    return projects;
  }
  return null;
};

export const getScreenList = async (projectId: string) => {
  if (projectId != null) {
    const screensData = await getScreens(projectId);
    const screens = screensData.map((screen: Screen) => {
      return {
        key: "screen_" + screen.id,
        value: screen.id,
        label: screen.sequence + " - " + screen.type + ": " + screen.title,
      };
    });
    screens.unshift({ key: 0, value: "", label: "--Select Screen--" });
    return screens;
  }
  return null;
};

export const getCategoryList = async (prod: string | null) => {
  if (prod) {
    const categoriesData = await getCategories(prod);
    const categories = categoriesData.map((category: string) => {
      return {
        key: "category_" + categoriesData.indexOf(category),
        value: category,
        label: category,
      };
    });
    categories.unshift({ key: 0, value: "", label: "--Select Category--" });
    return categories;
  }
  return null;
};

export const getActionsList = async (category: string) => {
  if (category != null) {
    const actionsData = await getActions(category);
    const actions = actionsData.map((action: string) => {
      return {
        key: "action_" + actionsData.indexOf(action),
        value: action,
        label: action,
      };
    });
    actions.unshift({ key: 0, value: "", label: "--Select Action--" });
    return actions;
  }
  return null;
};
