import React, { useState } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import UserLogin from "./UserLogin";
import "../global/utilities.scss";
import styles from "./NavMenu.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

interface Prop {
  children: any;
}

const NavMenu = (props: Prop) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
        color="dark"
        dark
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            <FontAwesomeIcon icon={solid("puzzle")} /> MQ Central
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse
            className="d-sm-flex flex-sm-row justify-content-between"
            isOpen={!collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow d-flex width-100">
              <AuthenticatedTemplate>
                <NavItem>
                  <NavLink
                    href={process.env.REACT_APP_HQ_URL_LOC}
                    className="text-light"
                    to={process.env.REACT_APP_HQ_URL_LOC}
                  >
                    HQ
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/insights">
                    Insights
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-light" to="/reports">
                    Reports
                  </NavLink>
                </NavItem>
              </AuthenticatedTemplate>

              <UserLogin {...props.children} />
            </ul>
          </Collapse>
        </Container>
        <span className={styles["navbar-environment-label"]}>
          {process.env.REACT_APP_ENVIRONMENT}
        </span>
      </Navbar>
    </header>
  );
};

export default NavMenu;
