import { Col } from "reactstrap";
import styles from "./tables/Table.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faS, faAngleUp, faAnglesUp, faAngleDown, faAnglesDown, faEquals } from '@fortawesome/pro-solid-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faS, faAngleUp, faAnglesUp, faAngleDown, faAnglesDown, faEquals)

interface Props {
    props: [number, number]
}

const Trends = (props: Props) => {

    const [average, last30Days] = props.props
    const trendDiff = last30Days - average
    return (
        <>
            {average === 0 ? "No Data" :
                <Col>
                    {trendDiff > 0 && (trendDiff) / average > 0.2 ?
                        <FontAwesomeIcon className={styles.angleUp} icon={["fas", "angles-up"]} /> :
                        trendDiff > 0 ?
                            <FontAwesomeIcon className={styles.angleUp} icon={["fas", "angle-up"]} /> :
                            trendDiff < 0 && (trendDiff) / average < -0.2 ?
                                <FontAwesomeIcon className={styles.angleDown} icon={["fas", "angles-down"]} /> :
                                trendDiff < 0 ?
                                    <FontAwesomeIcon className={styles.angleDown} icon={["fas", "angle-down"]} /> :
                                    <FontAwesomeIcon className={styles.equal} icon={["fas", "equals"]} />
                    }
                </Col>
            }
        </>
    )
}

export default Trends