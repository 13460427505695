import React, { ChangeEventHandler } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { InputType } from "reactstrap/es/Input";

interface Option {
  key: number;
  value: string;
  label: string;
}

interface Props {
  label: string;
  id: string;
  className?: string;
  onChange: ChangeEventHandler<any> | undefined;
  type: InputType;
  options?: Option[];
}

const CustomInput = (props: Props) => {
  if (props.type === "select") {
    return (
      <FormGroup className={props.className}>
        <Label for={props.id} id={`form-label__${props.id}`}>
          {props.label}
        </Label>
        <Input
          type={props.type}
          id={props.id}
          name={props.id}
          className="form-select"
          onChange={props.onChange}
        >
          {props.options?.map((option: Option) => (
            <option key={option.key} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  } else {
    return (
      <FormGroup>
        <Label for={props.id} id={`form-label__${props.id}`}>
          {props.label}
        </Label>
        <Input
          type={props.type}
          id={props.id}
          name={props.id}
          onChange={props.onChange}
        />
        <FormFeedback>Date Range Invalid</FormFeedback>
      </FormGroup>
    );
  }
};

export default CustomInput;
