import React from "react";
import { msalInstance } from "../services/msal/MsalInstance";

export const AuthContext = React.createContext({
  onSignOut: () => { return },
  onSignIn: () => { return },
});

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const signOutHandler = () => {
    msalInstance.logoutRedirect();
  };

  const signInHandler = () => {
    const accounts = msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      // No user signed in
      try {
        msalInstance.loginRedirect({
          scopes: ["User.Read"],
        });
      } catch (error) {
        console.log("loginRedirect AUTHENTICATION FAILED", error);
      } finally {
        console.log("SUCCESSFULLY SIGNED IN");
      }
    }

    msalInstance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        // Check if the tokenResponse is null
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
        // If the tokenResponse === null, you are not coming back from an auth redirect.
        if (tokenResponse !== null) {
          console.log("SUCCESSFUL AUTHENTICATION REDIRECT");
          console.log(accounts);
        } else {
          console.log(
            "signInHandler AUTHENTICATION FAILED: tokenResponse is null"
          );
        }
      })
      .catch((error) => {
        // handle error, either in the library or coming back from the server
        console.log("signInHandler AUTHENTICATION FAILED", error);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        onSignOut: signOutHandler,
        onSignIn: signInHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
